<template>
  <div class="row no-gutters">
    <div class="col-12">
      <div class="row no-gutters">
        <div class="col-10 offset-1 col-lg-12 offset-lg-0">
          <div class="m-card text-center">
            <h1 class="o-heading o-heading--xl">
              {{ $t(labelRadix+".title") }}
            </h1>
            <p>
              {{ $t(labelRadix+".subtitle") }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="col-10 offset-1 col-lg-4 offset-lg-4 pt-5 ">
      <div class="m-card ">
        <div class="m-card__img">
          <b-img-lazy
            src="../../assets/email-send.svg"
            alt="operatore"
          ></b-img-lazy>
        </div>
        <div class="m-card__form">
          <b-form-group
            id="username"
            :label="this.$t(labelRadix+'.username')"
            label-for="username-input"
            class="m-input"
          >
            <b-form-input
              id="username-input"
              v-model="data.username"
              type="text"

              :placeholder="this.$t(labelRadix+'.username_placeholder')"
              required
            ></b-form-input>
          </b-form-group>
          <b-form-group
            id="email"
            :label="this.$t(labelRadix+'.email')"
            label-for="email-input"
            class="m-input"
          >
            <b-form-input
              id="email-input"
              v-model="data.email"
              type="text"

              :placeholder="this.$t(labelRadix+'.email_placeholder')"
              required
            ></b-form-input>
          </b-form-group>

          <div class="m-card__action mt-2">
            <b-button @click="doAction()" class=" mb-2 text-light " variant="secondary" size="md" :disabled="this.data.username === '' || this.data.email === ''">{{
              $t(labelRadix+".action")
            }}</b-button>
          </div>
        </div>
      </div>
    </div>
    <b-modal
             id="invalid-user-pass"
             centered
             modal-class="o-modal"
             content-class="o-modal__content"
             header-class="o-modal__header"
             dialog-class="o-modal__dialog"
             body-class="o-modal__body o-modal__body--response "
             ok-title="ok-title"
             :cancel-disabled="false"
             :hide-footer="true"
             :hide-header-close="false"
             :hide-header="false"
             size="lg"
    >
      <div class="o-modal__body--icon-close">
        <span class="icon-close"></span>
        <span class="icon-close text-light bg-warning"></span>
      </div>
      <p class="o-modal__body--title text-warning">
        <strong> Account non valido </strong>
      </p>
      <p>
        Username ed email non riportano ad un account valido
      </p>
      <br>
      <p>{{$t('resetMail.message')}}<router-link to="resetMail"><strong><u>{{$t('resetMail.message_link')}}</u></strong></router-link></p>

    </b-modal>

  </div>
</template>

<script>
import apiAxios from "@/libs/apiAxios";
import router from "@/router";

export default {
  name: "AppRiattivaUtente",
  props:{
    service:{
      dafault:"reactivate"
    }
  },


  data() {
    return {
      passwordHide: "*********",

      data: {username:"", email:""},
    };
  },
  computed: {
    labelRadix:function (){
      switch (this.service)
      {
        case "forgot":
          return "forgot";
        default:
          return "riattivaUtente";
      }
    }
  },

  methods: {
     doAction:function() {
      const postData={
        urlSuccess:(this.labelRadix=="forgot")?document.location.origin+"/accessToken":document.location.origin+"/attivazioneUtenteLink",
        urlFail:document.location.origin+"/attivazioneUtenteLink",
        username:this.data.username,
        email:this.data.email
      };

      const data64=btoa(JSON.stringify(postData));
      if (this.labelRadix=="riattivaUtente") {
         apiAxios.post("account/reactivate", postData)
            .then(response => {
             const res=response.data.ReactivateResult;
             if (res<0)
             {
               this.$bvModal.show("invalid-user-pass");
               return;
             }
             if (res)
             {
               router.push("emailRegistrazione?service=reactivate&data="+data64);
             }
            })
            .catch(error => {
              console.log(error)
            });
      }

       if (this.labelRadix=="forgot") {
         apiAxios.post("account/forgot", postData)
             .then(response => {
               const res=response.data.ForgotResult;
               if (res<0)
               {
                 this.$bvModal.show("invalid-user-pass");
                 return;
               }
               if (res)
               {
                 router.push("emailRegistrazione?service=forgot&data="+data64);
               }
             })
             .catch(error => {
               console.log(error)
             });
       }
    }
  },
};
</script>
